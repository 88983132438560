import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import {
  galleryItemsType,
  FastGalleryPluginEvents as Events,
} from '../../constants/generalConstants';

/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'fastGalleryPlugin2',
  props: {
    // Here you can define your widget props
    items: galleryItemsType,
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
    [Events.GetMoreItems]: {
      description: 'Fired when reached to getMoreItemThreshold',
    },
  },
});
