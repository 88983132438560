import { FastGalleryPluginEvents as Events } from '../constants/generalConstants';

export function getStringFromNumber(num: number) {
  if (num < 10) {
    return '0' + num;
  } else {
    return num;
  }
}

export const getSelectorFromName = (name) => {
  return name.includes('#') ? name : `#${name}`;
};

export const initializeFastGalleryPlugin = ({
  $bind,
  $widget,
  flowAPI,
  $w,
}) => {
  $widget.onPropsChanged((_, newProps) => {
    const { items } = newProps;

    $w('#fastGallery1').items = items;
    $w('#expandModPolyWidget1').items = items;
  });

  const initWidget = async () => {
    $w('#fastGallery1').items = $widget.props.items || [];
    $w('#expandModPolyWidget1').items = $widget.props.items || [];

    $w('#fastGallery1').onItemClicked((event) => {
      const { itemIndex } = event;

      $w('#expandModPolyWidget1').currentIndex = itemIndex;

      $w('#fastGallery1').setExpandOpen(true);
    });

    $w('#fastGallery1').onGetMoreItems(async () => {
      $widget.fireEvent(Events.GetMoreItems, {});
    });

    $w('#expandModPolyWidget1').onCloseExpandModeClicked(() => {
      $w('#fastGallery1').setExpandOpen(false);
    });
  };

  return {
    pageReady: async () => {
      initWidget();
    },
    exports: {},
  };
};
