import { initializeFastGalleryPlugin } from '../../utils/utils';
import model from './model';

const initializeButtonFunctions = ($w) => {
  $w('#buttonPrev').onClick(() => {
    $w('#fastGallery1').previous();
  });

  $w('#buttonNext').onClick(() => {
    $w('#fastGallery1').next();
  });
};

const InitializeFastGalleryPlugin2 = (params) => {
  const { $w } = params;
  const pluginInstance = initializeFastGalleryPlugin(params);
  initializeButtonFunctions($w);
  return pluginInstance;
};

export default model.createController(InitializeFastGalleryPlugin2);
